import { getCommonParamsOptions, getParamsOptionsAsync } from '@/utils/common'

export const searchFormConfig = {
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'file.application-name'
    },
    {
      field: 'tenantId',
      type: 'treeSelect',
      label: 'user.tenant',
      isResolveGlobalParams: true,
      otherOptions: {
        props: {
          multiple: true
        }
      },
      options: [],
      handler: () =>
        getParamsOptionsAsync('tenantList', {
          isNeedPreFix: false
        })
    },
    {
      field: 'author',
      type: 'input',
      label: 'file.author'
    },
    {
      field: 'company',
      type: 'input',
      label: 'file.company'
    },
    {
      field: 'appType',
      type: 'select',
      label: 'file.application-type',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('app_type')
    },
    {
      field: 'status',
      type: 'select',
      label: 'general.status',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('status')
    },
    {
      field: 'creationDate',
      type: 'datepicker',
      label: 'general.creation-date',
      otherOptions: {
        type: 'daterange'
      },
      dateFieldName: ['startDateTime', 'endDateTime']
    }
  ]
}
