import { getCommonParamsItemLabel } from '@/utils/common'

export const contentTableConfig = {
  // title: '('common.app-application-list')',
  title: '',
  propList: [
    {
      prop: 'name',
      label: 'file.application-name'
    },
    {
      prop: 'tenantName',
      label: 'file.tenant'
    },
    {
      prop: 'author',
      label: 'file.author'
    },
    {
      prop: 'company',
      label: 'file.company'
    },
    {
      prop: 'downloadCount',
      label: 'file.download-counts'
    },
    {
      prop: 'appType',
      label: 'file.application-type',
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'app_type')
      }
    },
    {
      prop: 'status',
      width: '125',
      label: 'general.status',
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'status')
      }
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time',
      width: '200'
    }
  ],
  showIndexColumn: true
}
